<template>
    <div class="reports is-width-fill">
        <AveragesReport
            :averagesReport="averagesReport"
        />

        <TotalSalesReport
            :totalSalesReport="totalSalesReport"
        />

        <OrdersByLocationReport
            :ordersByLocationReport="ordersByLocationReport"
        />

        <OrderActivityReport
            :orderActivityReport="orderActivityReport"
        />

        <TopItemsReport
            :topItems="topItems"
            @export="exportTopItems"
            @get-page="getTopItemsPage"
        />

        <DigitalFulfillmentReport
            :digitalFulfillmentReport="digitalFulfillmentReport"
            @export="exportDigitalFulfillment"
        />

        <FullStatementReport
            :fullStatement="fullStatement"
            @export="exportFullStatement"
        />
    </div>
</template>

<script setup lang="ts">

import {onMounted, provide, ref} from "vue";
import { usePage } from "@inertiajs/vue3";
import Base from "@/Layouts/Base.vue";
import Default from "@/Layouts/Default.vue";
import {Report, ReportInjection} from "@/Types/Report";
import axios from "axios";
import { route } from "ziggy-js";
import { useFileDownload } from "@/Helpers/useFileDownload";
import AveragesReport from './Reports/AveragesReport.vue';
import TotalSalesReport from './Reports/TotalSalesReport.vue';
import OrdersByLocationReport from "@/Pages/Shop/Admin/Reports/OrdersByLocationReport.vue";
import TopItemsReport from "@/Pages/Shop/Admin/Reports/TopItemsReport.vue";
import DigitalFulfillmentReport from "@/Pages/Shop/Admin/Reports/DigitalFulfillmentReport.vue";
import FullStatementReport from "@/Pages/Shop/Admin/Reports/FullStatementReport.vue";
import OrderActivityReport from "./Reports/OrderActivityReport.vue";

defineOptions({
    layout: [Base, Default]
});

const averagesReport = ref<Report['averagesReport'] | undefined>(undefined);
const totalSalesReport = ref<Report['totalSalesReport'] | undefined>(undefined);
const ordersByLocationReport = ref<Report['ordersByLocationReport'] | undefined>(undefined);
const orderActivityReport = ref<Report['orderActivityReport'] | undefined>(undefined);
const fullStatement = ref<Report['fullStatement'] | undefined>(undefined);
const topItems = ref<Report['topItems'] | undefined>(undefined);
const digitalFulfillmentReport = ref<Report['digitalFulfillmentReport'] | undefined>(undefined);

function getPrettyFieldName(fieldName: string) {
    // Convert SNAKE_CASE to Title Case and camelCase to Title Case
    return fieldName.replace(/_/g, ' ').replace(/([a-z1-9])([A-Z])/g, '$1 $2').replace(/^./, str => str.toUpperCase());
}

onMounted(async () => {
    fetchReports();
});

async function fetchReports(only?: string[]) {
    const defaultOnly = [
        'averagesReport',
        'totalSalesReport',
        'ordersByLocationReport',
        'orderActivityReport',
        'fullStatement',
        'topItems',
        'digitalFulfillmentReport',
    ];

    if (!only) {
        only = defaultOnly;
    }

    const thisRoute = route('admin.shops.reports.fetch', { shop: usePage().props.shopSlug });
    const { status, payload } = (await axios.get(thisRoute, {
        params: {
            only
        }
    })).data;

    if (status === 'OK') {
        if (payload?.averagesReport) {
            averagesReport.value = payload.averagesReport;
        }
        if (payload?.totalSalesReport) {
            totalSalesReport.value = payload.totalSalesReport;
        }
        if (payload?.ordersByLocationReport) {
            ordersByLocationReport.value = payload.ordersByLocationReport;
        }
        if (payload?.orderActivityReport) {
            orderActivityReport.value = payload.orderActivityReport;
        }
        if (payload?.fullStatement) {
            fullStatement.value = payload.fullStatement;
        }
        if (payload?.topItems) {
            topItems.value = payload.topItems;
        }
        if (payload?.digitalFulfillmentReport) {
            digitalFulfillmentReport.value = payload.digitalFulfillmentReport;
        }
    }
}

async function getTopItemsPage(url: string) {
    const { status, payload } = (await axios.get(url, {
        params: {
            only: [
                'topItems'
            ]
        }
    })).data;

    if (status === 'OK') {
        topItems.value = payload.topItems;
    }
}

function formatNumber(num: number): string {
    const USD = Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
    });

    const isNegative = (num ?? 0) < 0;
    const formatted = USD.format(Math.abs(num));

    return isNegative ? `(${formatted})` : formatted;
}

const isExporting = ref(false);

async function exportTopItems() {
    try {
        isExporting.value = true;

        const thisRoute = route('admin.shops.reports.export_top_items', {
            shop: usePage().props.shopSlug,
        });
        await useFileDownload(thisRoute, 'top_items.csv');
    } catch (e) {
        console.error(e);
    } finally {
        isExporting.value = false;
    }
}

async function exportFullStatement() {
    try {
        isExporting.value = true;

        const thisRoute = route('admin.shops.reports.export_full_statement', {
            shop: usePage().props.shopSlug,
        });
        await useFileDownload(thisRoute, 'full_statement.csv');
    } catch (e) {
        console.error(e);
    } finally {
        isExporting.value = false;
    }
}

async function exportDigitalFulfillment(id?: number) {
    try {
        isExporting.value = true;

        const thisRoute = route('admin.shops.reports.export_digital_fulfillment', {
            shop: usePage().props.shopSlug,
            id,
        });
        await useFileDownload(thisRoute, 'digital_fulfillment.csv');
        fetchReports(['digitalFulfillmentReport']);
    } catch (e) {
        console.error(e);
    } finally {
        isExporting.value = false;
    }
}

provide<ReportInjection>('shopReports', {
    getPrettyFieldName,
    formatNumber,
    isExporting,
});

</script>

<style lang="scss">
.reports {
    --vis-font-family: margin-mvb, sans-serif;
    --vis-color0: hsl(calc(var(--bulma-primary-h) + 0deg), 100%, 70%);
    --vis-color1: hsl(calc(var(--bulma-primary-h) + 40deg), 80%, 80%);
    --vis-color2: hsl(calc(var(--bulma-primary-h) + 80deg), 100%, 70%);
    --vis-color3: hsl(calc(var(--bulma-primary-h) + 120deg), 80%, 80%);
    --vis-color4: hsl(calc(var(--bulma-primary-h) + 160deg), 100%, 70%);
    --vis-color5: hsl(calc(var(--bulma-primary-h) + 200deg), 80%, 80%);
    --vis-axis-tick-color: var(--bulma-primary-soft);
    --vis-axis-grid-color: var(--bulma-primary-soft);
    --vis-axis-tick-label-color: var(--bulma-primary-light);
    --vis-axis-label-color: var(--bulma-primary-light);
    --vis-donut-background-color: transparent;
    --vis-axis-tick-label-font-size: 1.6em;
    --vis-map-feature-color: transparent;
    --vis-map-boundary-color: var(--bulma-primary-70);
    --bulma-skeleton-block-min-height: 10rem;

    .sub-box {
        background: var(--bulma-primary-05);
        border-radius: 1rem;
    }

    .map-key {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        padding: 1rem;
        display: flex;
        align-items: center;
        gap: .5em;

        .key {
            width: 100%;
            height: 1rem;
            max-width: 7rem;
            background: linear-gradient(to right, hsl(var(--bulma-primary-h), 80%, 20%), hsl(var(--bulma-primary-h), 80%, 90%));
            border: 1px solid var(--bulma-primary-70);
        }
    }
}

.is-sticky-header {
    position: sticky;
    top: 0;
    z-index: 10;
}
</style>
