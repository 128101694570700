<template>
    <div class="box" v-if="props.fullStatement">
        <div class="is-flex is-justify-content-space-between is-align-items-flex-start">
            <div class="box-title">Full Statement</div>
            <div>
                <button
                    class="button is-primary is-small"
                    :class="{ 'is-loading': isExporting }"
                    :disabled="isExporting"
                    @click="emit('export')"
                >
                    Export
                </button>
            </div>
        </div>

        <div
            class="table-container"
            style="max-height: 80vh;"
        >
            <table
                class="table is-width-100 is-bordered is-hoverable"
            >
                <thead class="has-background-primary-25">
                <tr>
                    <th class="is-sticky-header has-background-primary-25">Order ID</th>
                    <th
                        v-for="(field, key) in fullStatement.totals"
                        style="max-width: 8em;"
                        class="is-sticky-header has-background-primary-25"
                    >
                        {{getPrettyFieldName(key) }}
                    </th>
                </tr>
                </thead>
                <tbody class="has-background-primary-05">
                <tr
                    v-for="(report) in fullStatement.report"
                    :key="report.orderId"
                >
                    <template v-for="(value, key) in report">
                        <td v-if="key === 'orderId'">
                            <template v-if="page.props.auth?.can?.viewShopAdminDashboard">
                                <Link
                                    class="is-clickable is-underlined"
                                    :href="route('orders.show', { order: report.orderId })"
                                >
                                    {{ value }}
                                </Link>
                            </template>
                            <template v-else>
                                {{ value }}
                            </template>
                        </td>
                        <td v-else>{{ formatNumber(value as number) }}</td>
                    </template>
                </tr>
                </tbody>
                <tfoot class="has-background-primary-25">
                <tr>
                    <th class="py-6">Total</th>
                    <template v-for="(value, key) in fullStatement.totals">
                        <td class="py-6">{{ formatNumber(value) }}</td>
                    </template>
                </tr>
                </tfoot>
            </table>
        </div>
    </div>
    <div v-else class="box">
        <div class="skeleton-block"></div>
    </div>
</template>

<script setup lang="ts">
import {Report, ReportInjection} from "@/Types/Report";
import {inject} from "vue";
import {route} from "ziggy-js";
import {Link, usePage} from "@inertiajs/vue3";

const props = defineProps<{
    fullStatement: Report['fullStatement'];
}>();

const emit = defineEmits<{
    (e: 'export'): void;
}>();

const page = usePage();

const {
    getPrettyFieldName,
    formatNumber,
    isExporting,
} = inject<ReportInjection>('shopReports');
</script>

<style scoped>
.table-container {
    overflow-y: auto !important;
}
</style>
