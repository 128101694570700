<template>
    <div class="is-flex is-flex-direction-column is-justify-content-center" style="gap: .75rem;">
        <div class="is-size-4 is-flex is-align-items-center has-gap-medium">
            <div>{{ goal.name }}</div>
            <div class="is-size-6">
                <div v-if="goalOpen && !isReached" class="tag is-warning">Active</div>
                <div v-else-if="!goalOpen && !isReached" class="has-text-white">Ended</div>
                <div v-else-if="isReached && goal.type === 'target'" class="has-text-success">Goal reached!</div>
            </div>
        </div>

        <div v-if="goal.type === 'target'" class="is-flex is-flex-direction-column has-gap-small">
            <div id="goal-progress">
                <div class="progress-bar" ref="progressBar"></div>
            </div>
            <div class="is-size-6 is-size-5-desktop has-text-weight-semibold is-flex has-gap-small is-width-100">

                <div v-if="type === 'dollar'" class="is-flex-grow-1">${{ animatedProgress.toFixed(2) }} / ${{
                    goal.target.toFixed(2) }}</div>
                <div v-else-if="type === 'vote'" class="is-flex-grow-1">{{ Math.round(animatedProgress) }} / {{
                    goal.target }} votes
                </div>
            </div>
        </div>
        <div v-else class="is-size-4 is-size-3-desktop has-text-weight-semibold mb-2" style="line-height: 1em;">
            <div v-if="type === 'dollar'">${{ animatedProgress.toFixed(2) }}</div>
            <div v-else-if="type === 'vote'">{{ Math.round(animatedProgress) }} vote{{ Math.round(animatedProgress) !== 1
                ? 's' : '' }}</div>
        </div>

        <div v-if="clickable" class="is-size-6 has-text-link is-flex is-align-items-center has-gap-small">More details
            <Icon :icon="mdiOpenInNew"></Icon>
        </div>
    </div>
</template>

<script setup lang="ts">
import { Goal } from '@/Types/Goal';
import dayjs from 'dayjs';
import gsap from 'gsap';
import { computed, onMounted, PropType, ref } from 'vue';
import Icon from './Icon.vue';
import { mdiOpenInNew } from '@mdi/js';

const animatedProgress = ref(0);
const isReached = ref(false);
const progressBar = ref<HTMLElement | null>(null);

const goalOpen = computed(() => {
    return dayjs().isBefore(props.goal.dateClose);
});

const props = defineProps({
    goal: {
        type: Object as PropType<Goal>,
        required: true
    },
    type: {
        type: String as PropType<"dollar" | "vote">,
    },
    clickable: {
        type: Boolean as PropType<boolean>,
        default: false
    }
})

onMounted(() => {
    const animationParams = {
        duration: 1.5,
        delay: .4,
        ease: props.goal.current >= props.goal.target ? "power1.out" : "power3.out"
    }

    if (props.goal.type === 'indeterminate') {
        gsap.to(animatedProgress, {
            value: props.goal.current,
            ...animationParams
        });
    } else {
        gsap.to(animatedProgress, {
            value: props.goal.current,
            ...animationParams,
            onComplete: function () {
                isReached.value = animatedProgress.value >= props.goal.target;
            }
        });

        gsap.to(progressBar.value, {
            width: `${(Math.min(props.goal.current, props.goal.target) / props.goal.target) * 100}%`,
            ...animationParams,
            onComplete: () => {
                if (props.goal.current >= props.goal.target) {

                    gsap.fromTo(progressBar.value, {
                        background: "#d6e8e7"
                    }, {
                        background: "#48c78e",
                        duration: .5,
                        ease: "power2.out"
                    });

                } else {

                    if (!goalOpen.value) {
                        return;
                    }


                    gsap.fromTo(progressBar.value, {
                        '--bg-offset': 'calc(0% - 40rem)',
                    }, {
                        '--bg-offset': 'calc(100% - 0rem)',
                        repeat: -1,
                        duration: 1.5,
                        ease: "power1.in"
                    });

                }
            }
        });
    }
});
</script>

<style lang="scss" scoped>
@use 'sass:color';
@use 'bulma/sass/utilities/mixins';

#goal-progress {
    width: 100%;
    height: 2rem;
    border-radius: var(--bulma-radius);
    background-color: var(--bulma-primary-10);
    overflow: hidden;
    box-sizing: border-box;
    border: 1px solid;
    border-color: var(--bulma-primary-35);

    .progress-bar {
        width: 0;
        height: 100%;
        border-radius: var(--bulma-radius);
        --bg-offset: calc(0% - 40rem);
        background: linear-gradient(to right,
                var(--bulma-link-text) var(--bg-offset),
                var(--bulma-primary-80) calc(var(--bg-offset) + 38rem),
                var(--bulma-link-text) calc(var(--bg-offset) + 40rem));
    }
}

@include mixins.tablet-only {
    #goal-progress {
        height: 1.25rem;
    }
}

@include mixins.mobile {
    #goal-progress {
        height: .75rem;
    }
}
</style>