<template>
    <div class="is-width-100">
        <CheckoutProgress class=" is-height-fit" :status="'goals'" />
        <div class="is-width-100">
            <div v-if="Object.keys(goalsPerShop).length === 0"
                class="box is-width-100 is-size-5 is-flex is-align-items-center has-gap-small is-flex-wrap-wrap">
                <Icon :icon="mdiCheck" />
                All goals in this shop have been fully contributed to. Please continue to checkout.
            </div>
            <div v-else v-for="(shopGoals, k, i) in goalsPerShop"
                class="box is-width-100 is-flex is-flex-direction-column">
                <h1 class="box-title mb-5 block">
                    <span>Set Goal Contributions</span>
                    <span v-if="goalsPerShopLength > 1"> (Shop {{ i + 1 }} / {{ goalsLength - 1 }})</span>
                </h1>

                <div class="block is-flex is-flex-direction-column has-gap-large">
                    <div v-for="item in cartItemsPerShop[k]" class="is-flex is-align-items-center has-gap-large"
                        :style="`opacity: ${item.item.goalContribution > 0 ? '1' : '.6'}`">
                        <ShopCartItem :key="item.item.id" :cart-item="item" :editable="false" :minimize="true" />

                        <div class="is-size-5 is-size-6-mobile has-text-weight-medium">
                            <template v-if="shopGoalTypes[k] === 'dollar'">
                                Contributes ${{ item.item.goalContribution * item.quantity }}
                            </template>
                            <template v-else>
                                Contributes {{ item.item.goalContribution * item.quantity }} vote{{ item.item.goalContribution * item.quantity !== 1 ? 's' : '' }}
                            </template>
                        </div>

                    </div>
                </div>

                <template v-if="maxContributionsPerShop[k] > 0">
                    <div class="block is-flex is-flex-direction-column has-gap-small">
                        <div class="is-size-3 has-text-weight-semibold mr-3" style="border-radius: .5rem;">
                            <template v-if="shopGoalTypes[k] === 'dollar'">
                                ${{ availableContributionsPerShop[k] }} remaining
                            </template>
                            <template v-else>
                                {{ availableContributionsPerShop[k] }} vote{{ availableContributionsPerShop[k] !== 1 ? 's' : '' }} remaining
                            </template>
                        </div>
                        <div
                            class="is-size-6 has-text-link-65 is-flex is-flex-wrap-wrap is-align-items-center has-gap-small">
                            <Icon :icon="mdiInformationOutline" /> Items purchased contribute {{ shopGoalTypes[k] === 'dollar' ? "dollars" : "votes" }} to shop goals.
                            Please select your contributions here.
                        </div>
                    </div>

                    <div class="goals-grid">
                        <div v-for="goal in shopGoals"
                            class="p-5 has-background-primary-05 is-flex is-flex-direction-column has-gap-large is-width-100"
                            style="border-radius: .5rem; border: 1px solid rgba(221, 221, 221, 0.2);">
                            <GoalDetails :goal="goal" :type="shopGoalTypes[k]" :compact="true"
                                :added-contributions="contributionsPerGoal[goal.id]" />
                            <div class="is-flex is-flex-wrap-wrap is-align-items-center is-justify-content-space-between has-gap-small mt-auto py-3 px-5 has-background-primary-20"
                                style="border-radius: .5rem;">
                                <div class="mr-3 is-size-5 is-primary">Contribution</div>
                                <div
                                    class="is-flex-desktop is-align-items-center has-gap-medium is-flex-wrap-wrap is-block-touch">
                                    <button class="button is-ghost px-0"
                                        @click="contributionsPerGoal[goal.id] = 0">Remove
                                        All</button>

                                    <template v-if="goal.type === 'target'">
                                        <QuantitySelector class="is-width-fit" :min="0"
                                            :max="Math.min(goal.target - goal.current, availableContributionsPerShop[k] + contributionsPerGoal[goal.id])"
                                            v-model:model-value="contributionsPerGoal[goal.id]"></QuantitySelector>
                                        <button class="button is-ghost px-0"
                                            @click="addAll(goal.id, Math.min(goal.target - goal.current, availableContributionsPerShop[k]))">Add
                                            Remaining</button>
                                    </template>
                                    <template v-else>
                                        <QuantitySelector class="is-width-fit" :min="0"
                                            :max="availableContributionsPerShop[k] + contributionsPerGoal[goal.id]"
                                            v-model:model-value="contributionsPerGoal[goal.id]"></QuantitySelector>
                                        <button class="button is-ghost px-0"
                                            @click="addAll(goal.id, availableContributionsPerShop[k])">Add
                                            Remaining</button>
                                    </template>
                                </div>
                            </div>
                        </div>
                    </div>
                </template>
                <template v-else>
                    <div>No items from this shop are able to contribute to a goal.</div>
                </template>
            </div>
            <div class="box">
                <div class="is-flex is-justify-content-flex-end is-align-items-center has-gap-large is-flex-wrap-wrap">
                    <div class="is-flex is-flex-direction-column has-gap-small">
                        <div v-if="props.error"
                            class="is-flex is-align-items-center has-gap-small is-size-5 is-size-6-touch">
                            <Icon :icon="mdiAlertOutline" />
                            {{ props.error }}
                        </div>

                        <div v-if="!canCheckout"
                            class="is-flex is-align-items-center has-gap-small is-size-5 is-size-6-touch">
                            <Icon :icon="mdiInformationOutline" />
                            <span>Contribute all before checkout.</span>
                        </div>
                    </div>

                    <button v-if="Object.keys(goalsPerShop).length > 0" class="button is-warning is-large"
                        :class="{ 'is-loading': checkoutLoading }" :disabled="checkoutLoading || !canCheckout"
                        @click.prevent="handleContinueClick">
                        <Icon :icon="mdiCashRegister" class="mr-2" />
                        <span>Continue To Checkout</span>
                    </button>
                    <Link v-else :href="route('checkout.show')" class="button is-warning is-large"
                        :class="{ 'is-loading': checkoutLoading }" @click="() => checkoutLoading = true">
                    <Icon :icon="mdiCashRegister" class="mr-2" />
                    <span>Continue To Checkout</span>
                    </Link>

                    <div v-if="props.error" class="notification is-error">
                        {{ props.error }}
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import CheckoutProgress from '@/Components/CheckoutProgress.vue';
import GoalDetails from '@/Components/GoalDetails.vue';
import Icon from '@/Components/Icon.vue';
import QuantitySelector from '@/Components/QuantitySelector.vue';
import ShopCartItem from '@/Components/ShopCartItem.vue';
import Base from '@/Layouts/Base.vue';
import Default from '@/Layouts/Default.vue';
import { useCartStore } from '@/Stores/CartStore';
import { CartItem } from '@/Types/CartItem';
import { Goal } from '@/Types/Goal';
import { Link, router } from '@inertiajs/vue3';
import { mdiAlertOutline, mdiCashRegister, mdiCheck, mdiInformationOutline } from '@mdi/js';
import { computed, ComputedRef, onMounted, ref } from 'vue';

defineOptions({
    layout: [Base, Default],
});

const cart = useCartStore();
const cartItemsPerShop = ref<Record<number, CartItem[]>>({});
const checkoutLoading = ref(false);

const props = defineProps<{
    // contributions: Record<number, number>,
    error?: string,
    availableGoals: Record<number, Goal>,
    shopGoalTypes: Record<number, 'vote' | 'dollar'>,
}>();

/*
This is what you want to send to the backend
format is as follows: <goalId: number, contribution: number>
*/
const contributionsPerGoal = ref<Record<number, number | undefined>>({});

onMounted(async () => {
    await cart.initialize();

    cartItemsPerShop.value = cart.cartItems.reduce((acc, item) => {
        if (!acc[item.item.shopId]) {
            acc[item.item.shopId] = [];
        }

        acc[item.item.shopId].push(item);

        return acc;
    }, {} as Record<number, CartItem[]>);

    for (const i in Object.keys(props.availableGoals)) {
        contributionsPerGoal.value[props.availableGoals[i].id] = 0;
    }
});

const goalsLength = computed(() => {
    return Object.keys(props.availableGoals).length;
});

const goalsPerShopLength = computed(() => {
    return Object.values(goalsPerShop.value).length;
});

const goalsPerShop = computed(() => {
    return Object.values(props.availableGoals).reduce((acc, goal) => {
        if (!acc[goal.shopId]) {
            acc[goal.shopId] = [];
        }

        acc[goal.shopId].push(goal);

        return acc;
    }, {} as Record<number, Goal[]>);
});

const maxContributionsPerShop = computed(() => {
    const record: Record<number, number> = {};
    for (const i of Object.keys(cartItemsPerShop.value)) {
        const contributions = cartItemsPerShop.value[i].reduce((acc, item) => {
            return acc + item.item.goalContribution * item.quantity;
        }, 0);
        record[i] = contributions;
    }
    return record;
})

const availableContributionsPerShop: ComputedRef<Record<number, number>> = computed(() => {
    const record: Record<number, number> = JSON.parse(JSON.stringify(maxContributionsPerShop.value));
    for (const i of Object.keys(goalsPerShop.value)) {
        const contributions = goalsPerShop.value[i].reduce((acc, goal) => {
            return acc + contributionsPerGoal.value[goal.id];
        }, 0);
        record[i] -= contributions;
    }
    return record;
});

const canCheckout = computed(() => {
    for (const i of Object.keys(goalsPerShop.value)) {
        const shop = goalsPerShop.value[i];
        let allGoalsFull = true;

        for (const goal of shop) {
            if (goal.type === 'target' && (goal.current + contributionsPerGoal.value[goal.id]) < goal.target) {
                allGoalsFull = false;
            }
        }

        if (allGoalsFull) {
            continue;
        }

        if (availableContributionsPerShop.value[i] !== 0 && !isNaN(availableContributionsPerShop.value[i])) {
            return false;
        }
    }

    return true;
});

function addAll(goalId: number, availableContributions: number) {
    contributionsPerGoal.value[goalId] += availableContributions;
}

function handleContinueClick() {
    if (checkoutLoading.value || !canCheckout.value) {
        return;
    }

    const payload = [];

    for (const [goalId, amount] of Object.entries(contributionsPerGoal.value)) {
        payload.push({
            goalId,
            amount
        });
    }

    router.post(
        route('checkout.goals.store'),
        {
            contributions: payload
        },
        {
            onStart: () => {
                checkoutLoading.value = true;
            },
            onFinish: () => {
                checkoutLoading.value = false;
            }
        }
    )
}

</script>

<style lang="scss" scoped>
@use "bulma/sass/utilities/mixins";

.goals-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(33rem, 1fr));
    gap: 2rem;
    width: 100%;
}

@include mixins.touch {
    .goals-grid {
        display: flex;
        flex-direction: column;
    }
}
</style>
