<template>
    <div class="input is-primary is-flex is-align-items-center px-0">
        <button class="button is-ghost px-2" @click="decrement">
            <Icon :icon="mdiMinus" />
        </button>
        <input v-if="quantityModel !== undefined" type="number" :value="quantityModel" :min="min" :max="max" @input="modelChange"/>
        <input v-else type="number" :value="value" :min="min" :max="max"/>
        <button class="button is-ghost px-2" @click="increment">
            <Icon :icon="mdiPlus" />
        </button>
    </div>
</template>

<script setup lang="ts">
import { mdiMinus, mdiPlus } from '@mdi/js';
import Icon from './Icon.vue';

const quantityModel = defineModel<number>();

const emit = defineEmits(['change']);

const props = defineProps({
    value: {
        type: Number,
        required: false
    },
    min: {
        type: Number,
        default: 1
    },
    max: {
        type: Number,
        default: 99
    },
    clientSide: {
        type: Boolean,
        default: true
    }
});

function increment() {
    if (quantityModel.value < props.max && props.clientSide) {
        quantityModel.value++;
    } else if (props.value + 1 <= props.max && !props.clientSide) {
        emit('change', props.value + 1)
    }
}

function decrement() {
    if (quantityModel.value > props.min && props.clientSide) {
        quantityModel.value--;
    } else if (props.value - 1 >= props.min && !props.clientSide) {
        emit('change', props.value - 1)
    }
}

function modelChange(e) {
    const num = parseInt(e.target.value);
    if (isNaN(num)) {
        quantityModel.value = 0;
    } else {
        if (num < props.min) {
            quantityModel.value = props.min;
        } else if (num > props.max) {
            quantityModel.value = props.max;
        } else {
            quantityModel.value = num;
        }
    }
}

</script>

<style lang="scss" scoped>
@use "bulma/sass/utilities/mixins";

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    appearance: none;
    margin: 0;
}

input {
    -moz-appearance: textfield;
    appearance: textfield;
    background: none;
    border: none;
    color: white;
    font-size: 1rem;
    width: 3rem;
    text-align: center;
}
</style>