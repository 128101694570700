<template>
    <div class="is-width-100">
        <Dialog title="Goal" :open="goalModalOpen" @close="goalModalOpen = false" v-slot="{ onClose }">
            <GoalDetails :goal="goalModalGoal" :type="shop.goalType" @close="onClose" />
        </Dialog>

        <div v-if="shop.loading" class="banner-wrapper mb-6">
            <PageHead title="Shop" />

            <div class="banner">
                <div class="skeleton-block is-height-100"></div>
            </div>
            <div class="box banner-content is-flex is-flex-direction-column" style="gap: 1.5rem;">
                <div class="is-flex is-flex-direction-column has-gap-small">
                    <div class="skeleton-block mb-3">title</div>
                    <div class="skeleton-lines">
                        <div v-for="i in 3" />
                    </div>
                </div>
                <div class="top-grid">
                    <div v-for="i in 2" class="skeleton-block m-0" style="border: none;"></div>
                </div>
                <div class="is-skeleton is-width-100" style="height: 2.5rem;"></div>
            </div>
        </div>

        <div v-else class="banner-wrapper mb-6 is-flex is-flex-direction-column">
            <PageHead :title="shop.name" :description="shop.description" :image="shop.bannerUrl" />

            <LoadingImg class="banner" :src="shop.bannerUrl" />
            <div class="box banner-content is-flex is-flex-direction-column" style="gap: 1.75rem;">

                <div class="is-flex is-flex-direction-column has-gap-small">
                    <h1 class="is-size-2 is-size-3-mobile has-text-weight-bold">{{ shop.name }}
                    </h1>
                    <p class="is-size-5-desktop is-size-6-touch" v-html="shop.description.replaceAll('\n', '<br>')"></p>
                </div>

                <div v-if="shop.goals.length >= 6">
                    <div class="top-grid" :class="{ 'limit': !topGridExpanded }">
                        <ShopTimer :open-timestamp="shop.dateOpen" :close-timestamp="shop.dateClose" />
                        <ShopGoal class="is-clickable" v-for="goal in shop.goals" :goal="goal" :type="shop.goalType"
                            :clickable="true" @click="goalModalGoal = goal; goalModalOpen = true" />
                    </div>
                    <button v-if="!topGridExpanded"
                        class="expand button is-ghost py-0 pt-2 is-flex is-align-items-center is-size-5 is-size-6-mobile is-text-bold has-gap-small"
                        @click="topGridExpanded = true">
                        <Icon class='width-fit' :icon="mdiChevronDown" />
                        See all {{ shop.goals.length }} goals
                        <Icon :icon="mdiChevronDown" />
                    </button>
                    <button v-else
                        class="expand button is-ghost py-0 pt-2 is-flex is-align-items-center is-size-5 is-size-6-mobile is-text-bold has-gap-small"
                        @click="topGridExpanded = false">
                        <Icon class='width-fit' :icon="mdiChevronUp" />
                        Collapse
                        <Icon :icon="mdiChevronUp" />
                    </button>
                </div>

                <template v-else-if="shop.goals.length > 0">
                    <div class="top-grid">
                        <ShopTimer :open-timestamp="shop.dateOpen" :close-timestamp="shop.dateClose" />
                        <ShopGoal class="is-clickable" v-for="goal in shop.goals" :goal="goal" :type="shop.goalType"
                            :clickable="true" @click="goalModalGoal = goal; goalModalOpen = true" />
                    </div>
                </template>

                <template v-else>
                    <ShopTimer class="is-width-fit" :open-timestamp="shop.dateOpen" :close-timestamp="shop.dateClose"
                        :compact="true" />
                </template>

                <div class="top-options is-flex is-flex-wrap-wrap is-align-items-center m-0 has-gap-large">
                    <div class="control has-icons-left is-flex-grow-1" style="min-width: 15rem;">
                        <input class="input is-primary" type="text" placeholder="Search" v-model="searchQuery" />
                        <span class="icon is-left">
                            <Icon :icon="mdiMagnify" />
                        </span>
                    </div>
                    <div class="is-flex is-align-items-center">
                        <div class="mr-3 is-no-wrap">Sort by:</div>
                        <div class="select is-width-fill">
                            <select class="select is-primary is-width-fill" @change="sortChange" .value="shop.sort">
                                <option v-for="sort in shop.getSortOptions()" :value="sort.value">{{ sort.text }}
                                </option>
                            </select>
                        </div>
                    </div>
                    <template v-if="page.props.auth.can.viewShopAdminDashboard || page.props.auth.can.viewShopReports">
                        <Link :href="route('admin.shops.show', { shop: shop.slug })"
                            class="button is-warning has-gap-small">
                        <Icon :icon="mdiStoreCog" />
                        <span>Admin Panel</span>
                        <span class="tag is-uppercase is-black">Admin</span>
                        </Link>
                    </template>
                </div>
            </div>
        </div>

        <div class="shop-grid">
            <template v-if="shop && shop.items.length > 0">

                <template v-if="renderedSearchQuery !== ''" v-for="item in shop.items">
                    <ShopItem v-if="searchQueryHas(item.title)" :shop="shop" :key="item.id" :item="item"
                        :data-flip-id="item.id" />
                </template>

                <template v-else-if="shop.sort === 'category'">
                    <template v-for="category in Object.keys(itemsPerCategory)">

                        <div v-if="category"
                            class="category-header has-text-weight-semibold is-size-1-desktop is-size-2-tablet is-size-3-mobile has-text-white"
                            :data-flip-id="category">{{ category }}</div>
                        <div v-else class="category-header-invis"></div>

                        <ShopItem v-for="item in itemsPerCategory[category]" :shop="shop" :key="item.id" :item="item"
                            :data-flip-id="item.id" />
                    </template>
                </template>

                <template v-else>
                    <ShopItem v-for="item in shop.items" :key="item.id" :shop="shop" :item="item"
                        :data-flip-id="item.id" />
                </template>

                <div class="category-header-invis"></div>

            </template>

            <template v-else-if="shop.loading">
                <ShopItem v-for="i in 8" :skeleton="true" />
            </template>

        </div>
    </div>
</template>

<script setup lang="ts">
import ShopItem from '@/Components/ShopItem.vue';
import gsap from 'gsap';
import { Flip } from 'gsap/all';
import type { Sort } from '@/Types/Sort';
import { mdiChevronDown, mdiChevronUp, mdiMagnify, mdiStoreCog } from '@mdi/js';
import { useShopStore } from '@/Stores/ShopStore';
import { computed, nextTick, onMounted, ref, watch } from 'vue';
import { Link, usePage } from '@inertiajs/vue3';
import ShopGoal from '@/Components/ShopGoal.vue';
import ShopTimer from '@/Components/ShopTimer.vue';
import Icon from '@/Components/Icon.vue';
import LoadingImg from '@/Components/LoadingImg.vue';
import HasCart from '@/Layouts/HasCart.vue';
import Base from '@/Layouts/Base.vue';
import PageHead from '@/Components/PageHead.vue';
import Dialog from '@/Components/Dialog.vue';
import GoalDetails from '@/Components/GoalDetails.vue';
import { Goal } from '@/Types/Goal';

defineOptions({
    layout: [Base, HasCart]
});

gsap.registerPlugin(Flip);

const page = usePage();
const shop = useShopStore();
const searchQuery = ref<string>('');
const renderedSearchQuery = ref<string>('');
const shopId = Number(page.props.shopId);
const goalModalOpen = ref(false);
const goalModalGoal = ref<Goal>(null);
const topGridExpanded = ref(false);

onMounted(async () => {
    await shop.initialize(shopId);
});

watch(searchQuery, () => {
    queryChange(() => {
        renderedSearchQuery.value = searchQuery.value;
    });
});

const itemsPerCategory = computed(() => shop.getItemsPerCategory());

function queryChange(postState: Function) {
    const target = '.shop-grid > *:not(.no-animate)';
    const state = Flip.getState(target);

    postState();

    //if prefers-reduced-motion is set to reduce, don't animate
    if (!window.matchMedia('(prefers-reduced-motion: reduce)').matches) {
        nextTick(() => {

            Flip.from(state, {
                duration: .4,
                targets: target,
                ease: "power2.inOut",
                simple: true
            });
        });
    }
}

function sortChange(event: Event) {
    queryChange(() => {
        const target = event.target as HTMLSelectElement;
        shop.changeSort(target.value as Sort);
    });
}

function searchQueryHas(title: string) {
    return title.toLowerCase().includes(renderedSearchQuery.value.toLowerCase());
}

</script>

<style lang="scss" scoped>
@use 'bulma/sass/utilities/mixins';

.shop-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(21.5rem, 1fr));
    gap: 1.5rem;
}

.top-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1rem;
    overflow: hidden;

    &.limit {
        max-height: 40vh;
        mask: linear-gradient(to bottom, rgba(0, 0, 0, 1) 90%, rgba(0, 0, 0, 0) 100%);
    }
}

.expand {
    width: 100%;

    &::before,
    &::after {
        content: '';
        flex-grow: 1;
        display: block;
        height: 1px;
        background: var(--bulma-primary-65);
    }
}

.top-grid>* {
    border: 1px solid rgba(221, 221, 221, 0.1);
    border-radius: 1rem;
    padding: 1.5rem 2rem;
    justify-content: center;
    transition: background-color .15s;
}

.top-grid>*.is-clickable:not(.skeleton-block):hover {
    background-color: var(--bulma-primary-15);
}

.top-grid>*:not(.skeleton-block) {
    background-color: rgba(0, 0, 0, 0.1);
}

.category-header {
    grid-column: 1/-1;
    display: flex;
    align-items: center;
    white-space: nowrap;
    padding-top: 4rem;
}

.category-header:first-child {
    padding-top: 2rem;
}

.category-header:after {
    content: "";
    display: block;
    height: 2px;
    background: linear-gradient(to right, var(--bulma-primary-65) 0%, transparent 100%);
    width: -webkit-fill-available;
    width: fill-available;
    width: -moz-available;
    margin-left: 1.5rem;
}

.category-header-invis {
    grid-column: 1/-1;
}

.banner-wrapper {
    border-radius: 1rem;
    box-shadow: 0 0.5rem 1.5rem 0 rgba(0, 0, 0, 0.37);
    border: 1px solid rgba(221, 221, 221, 0.1);

    .banner {
        width: 100%;
        aspect-ratio: 4 / 1;
        backdrop-filter: blur(20px);
        object-fit: cover;
        border-radius: 1rem 1rem 0 0;
    }

    .box {
        border: none;
        border-radius: 0 0 1rem 1rem;
        margin: 0;
    }
}

@include mixins.touch {
    .top-grid {
        grid-template-columns: 1fr;
        gap: .5rem;

        &.limit {
            max-height: 75vh;
        }
    }

    .top-grid>* {
        padding: 1rem;
    }
}

@include mixins.mobile {
    .top-grid {
        gap: 1.5rem;
    }

    .top-grid>* {
        border: none;
        border-radius: 0;
        padding: 0;
        background: transparent !important;
    }

    .top-options {
        gap: 1rem;
    }

    .top-options>* {
        width: 100%;
    }

    .shop-grid {
        grid-template-columns: 1fr;
    }

    .category-header {
        white-space: wrap;
    }

    .category-header:after {
        display: none;
    }
}
</style>
