<template>
    <div>
        <template v-if="shop.loading">

            <PageHead title="" description="" image="" />

            <div class="shop-item-wrapper">

                <div class="img-selector">
                    <div class="skeleton-block" style="aspect-ratio: 1/1; max-height: 60vh;">
                    </div>
                </div>

                <div class="item-options mx-auto">
                    <div class="box is-flex is-flex-direction-column has-gap-large">
                        <div class="is-size-2 skeleton-lines mb-0">
                            <div />
                        </div>
                        <div class="is-size-1 skeleton-lines mb-0">
                            <div />
                        </div>
                        <div class="is-size-1 skeleton-lines mb-5">
                            <div />
                        </div>

                        <PriceTag class="is-skeleton is-size-3 is-size-4-mobile my-3 is-width-fit">$0.00</PriceTag>

                        <div class="skeleton-lines mb-4 mt-4">
                            <div />
                            <div />
                            <div />
                        </div>

                        <div class="is-flex is-flex-direction-column has-gap-small">
                            <div v-for="i in 4" class="buttons">
                                <div v-for="j in (5 - i)" class="button is-skeleton">Loading</div>
                            </div>
                        </div>

                        <button class="button is-skeleton is-large is-width-100">
                            <span>
                                Add to Cart
                            </span>
                        </button>
                    </div>
                </div>
            </div>
        </template>

        <div class="shop-item-wrapper" v-else-if="item">

            <PageHead :title="item.title" :description="item.description" :image="item.images[0]?.url" />

            <div v-if="item?.images?.length > 0" class="img-selector">
                <ImgSelector :srcArr="images" :detailed="true" v-model="imageIndex" :labels="imageLabels"
                    :relevantIndices="selectedVariantImageIndices" />
            </div>

            <div class="box item-options is-flex is-flex-direction-column is-align-items-flex-start mx-auto"
                style="gap: 2rem;">

                <div class="is-flex is-flex-direction-column has-gap-medium">

                    <Link :href="route('shop.show', { shop: shop.slug })"
                        class="is-flex is-align-items-center is-width-100 has-gap-medium">
                    <LoadingImg :src="shop.logoUrl" style="width: 2em; height: 2em; border-radius: .3em;" />
                    <div class="box-title m-0">{{ shop.name }}</div>
                    </Link>

                    <h1 class="is-size-3 has-text-weight-medium mb-1">{{ item?.title }}</h1>

                    <div v-if="item.artistName"
                        class="is-flex is-align-items-baseline has-gap-small is-size-5 is-flex-wrap-wrap">
                        <div>Design by:</div>
                        <a v-if="item.artistUrl" :href="item.artistUrl"
                            class="has-text-weight-medium is-flex is-align-items-center has-gap-small" target="_blank">
                            <div>{{ item.artistName }}</div>
                            <Icon :icon="mdiOpenInNew" />
                        </a>
                        <div v-else class="has-text-weight-medium">{{ item.artistName }}</div>
                    </div>

                </div>

                <div class="is-flex is-align-items-center has-gap-medium is-flex-wrap-wrap">
                    <PriceTag class="is-size-3 is-size-4-mobile has-text-weight-semibold my-3">
                        <div class="pb-1">${{ item?.price.toFixed(2) }}</div>
                    </PriceTag>
                    <div v-if="!inStock" class="tag is-warning is-size-4 is-size-5-mobile">
                        Sold Out!
                    </div>
                </div>

                <div class="is-flex is-flex-direction-column has-gap-small is-size-6">
                    <p class="block" v-if="item?.description?.length > 0"
                        v-html="item.description.replaceAll('\n', '<br>')">
                    </p>

                    <div class="block is-flex is-flex-direction-column has-gap-small is-size-6">
                        <div v-if="inStock && item?.stockLimit" class="is-flex is-align-items-center has-gap-small">
                            <Icon :icon="mdiPackageVariant" />
                            {{ item.stockLimit - item.metadata.qtySold }} left in stock!
                        </div>

                        <div v-if="item?.goalContribution && shop.goals.length > 0" class="is-flex is-align-items-center has-gap-small">
                            <Icon :icon="mdiVote" />
                            <template v-if="shop.goalType === 'dollar'">
                                This item contributes ${{ item.goalContribution }} to a goal!
                            </template>
                            <template v-else-if="shop.goalType === 'vote'">
                                This item contributes {{ item.goalContribution }} vote{{ item.goalContribution !== 1 ?
                                's' : '' }} to a
                                goal!
                            </template>
                        </div>
                    </div>
                </div>

                <template v-if="shopIsOpen(shop.dateOpen, shop.dateClose)">

                    <div class="is-flex is-flex-direction-column has-gap-large">
                        <div class="is-flex is-flex-direction-column has-gap-small"
                            v-if="sizeVariants && sizeVariants?.length > 0">
                            <label>Size</label>
                            <div class="buttons">
                                <button v-for="variant in sizeVariants" :key="variant?.id"
                                    class="button is-primary has-text-white"
                                    :class="{ 'is-outlined': selectedSizeIndex !== sizeVariants.indexOf(variant) }"
                                    @click="selectedSizeIndex = sizeVariants.indexOf(variant)">
                                    {{ variant?.variantName }}
                                </button>
                            </div>
                        </div>

                        <div class="is-flex is-flex-direction-column has-gap-small"
                            v-if="colorVariants && colorVariants.length > 0">
                            <label>Color</label>
                            <div class="buttons">
                                <button v-for="variant in colorVariants" :key="variant.id"
                                    class="button is-primary has-text-white"
                                    :class="{ 'is-outlined': selectedColorIndex !== colorVariants.indexOf(variant) }"
                                    @click="selectedColorIndex = colorVariants.indexOf(variant)">
                                    <template v-if="variant.type === 'color'">
                                        <span v-if="variant.color" class="color-preview"
                                            :style="{ backgroundColor: variant.color }"></span>
                                        <span :class="{ 'ml-3': variant.color }">{{ variant.variantName }}</span>
                                    </template>
                                </button>
                            </div>
                        </div>

                        <div class="is-flex is-flex-direction-column has-gap-small"
                            v-if="otherVariants && otherVariants.length > 0">
                            <label>Other</label>
                            <div class="buttons">
                                <button v-for="variant in otherVariants" :key="variant.id"
                                    class="button is-primary has-text-white"
                                    :class="{ 'is-outlined': selectedOtherIndex !== otherVariants.indexOf(variant) }"
                                    @click="selectedOtherIndex = otherVariants.indexOf(variant)">{{ variant.variantName
                                    }}</button>
                            </div>
                        </div>

                        <div class="is-flex is-flex-direction-column has-gap-small">
                            <label>Quantity</label>
                            <div class="is-width-fit">
                                <QuantitySelector v-model="quantity" :min="1" :max="99" />
                            </div>
                        </div>

                        <!-- <div class="is-flex is-flex-direction-column has-gap-small" v-if="activeGoals.length > 0">
                            <label>Contribute to Goal</label>
                            <div class="select is-primary is-width-100">
                                <select v-model="selectedGoalId" class="is-width-100">
                                    <option :value="null">Select Goal</option>
                                    <template v-for="goal in activeGoals">
                                        <template v-if="shop.goalType === 'dollar'">
                                            <option v-if="goal.type === 'target'" :value="goal.id">
                                                {{ goal.name }} [${{ goal.current }} / ${{ goal.target }}]
                                            </option>
                                            <option v-else-if="goal.type === 'indeterminate'" :value="goal.id">
                                                {{ goal.name }} [${{ goal.current }}]
                                            </option>
                                        </template>
                                        <template v-else-if="shop.goalType === 'vote'">
                                            <option v-if="goal.type === 'target'" :value="goal.id">
                                                {{ goal.name }} [{{ goal.current }} / {{ goal.target }} votes]
                                            </option>
                                            <option v-else-if="goal.type === 'indeterminate'" :value="goal.id">
                                                {{ goal.name }} [{{ goal.current }} vote{{ goal.current !== 1 ? 's' : ''
                                                }}]
                                            </option>
                                        </template>
                                    </template>
                                </select>
                            </div>
                            <div v-if="shop.goalType === 'dollar'" class="tag is-primary is-width-fit px-3 py-1 is-size-6">This item contributes ${{
                                item.goalContribution }} to a
                                goal!</div>
                            <div v-else-if="shop.goalType === 'vote'" class="tag is-primary is-width-fit px-3 py-1 is-size-6">This item contributes {{
                                item.goalContribution }} vote{{ item.goalContribution !== 1 ? 's' : '' }} to a
                                goal!</div>
                        </div> -->
                    </div>

                    <template v-if="!isAddingToCart && canAddToCart.status">
                        <button class="button is-primary is-large is-width-100" @click="addToCart">
                            <span class="icon is-small is-width-100">
                                <Icon :icon="mdiCartPlus" />
                            </span>
                            <span>
                                Add to Cart
                            </span>
                        </button>
                    </template>

                    <template v-else-if="!isAddingToCart && !canAddToCart.status">
                        <div class="is-flex has-gap-medium is-align-items-center is-flex-wrap-wrap is-width-100">
                            <button class="button is-primary is-large is-width-100" disabled>
                                <span class="icon is-small">
                                    <Icon :icon="mdiCartPlus" />
                                </span>
                                <span>
                                    Add to Cart
                                </span>
                            </button>
                            <div class="has-text-link">
                                {{ canAddToCart.requiredMessage }}
                            </div>
                        </div>
                    </template>

                    <template v-else>
                        <button class="button is-primary is-large is-loading is-width-100">
                            <span class="icon is-small">
                                <Icon :icon="mdiCartPlus" />
                            </span>
                            <span>
                                Add to Cart
                            </span>
                        </button>
                    </template>
                </template>

                <template v-else>
                    <div class="is-flex is-flex-direction-column has-gap-large is-width-100">
                        <div class="is-flex is-flex-direction-column has-gap-small"
                            v-if="sizeVariants && sizeVariants?.length > 0">
                            <label>Size</label>
                            <div class="buttons">
                                <div v-for="variant in sizeVariants" :key="variant?.id"
                                    class="tag is-large is-primary has-text-white">{{ variant.variantName }}
                                </div>
                            </div>
                        </div>

                        <div class="is-flex is-flex-direction-column has-gap-small"
                            v-if="colorVariants && colorVariants.length > 0">
                            <label>Color</label>
                            <div class="buttons">
                                <div v-for="variant in colorVariants" :key="variant.id"
                                    class="tag is-large is-primary has-text-white">
                                    <template v-if="variant.type === 'color'">
                                        <span v-if="variant.color" class="color-preview"
                                            :style="{ backgroundColor: variant.color }"></span>
                                        <span :class="{ 'ml-3': variant.color }">{{ variant.variantName }}</span>
                                    </template>
                                </div>
                            </div>
                        </div>

                        <div class="is-flex is-flex-direction-column has-gap-small"
                            v-if="otherVariants && otherVariants.length > 0">
                            <label>Other</label>
                            <div class="buttons">
                                <div v-for="variant in otherVariants" :key="variant.id"
                                    class="tag is-large is-primary has-text-white">{{
                                        variant.variantName }}</div>
                            </div>
                        </div>
                    </div>
                    <div class="button is-large is-primary is-width-100" disabled>
                        <span class="icon is-small is-width-100">
                            <Icon :icon="mdiCartRemove" />
                        </span>
                        <span>
                            Shop is Closed
                        </span>
                    </div>
                </template>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import type { Item } from '@/Types/Item';
import { mdiCartPlus, mdiCartRemove, mdiOpenInNew, mdiPackageVariant, mdiVote } from '@mdi/js';
import type { ItemVariant } from '@/Types/ItemVariant';
import HasCart from '@/Layouts/HasCart.vue';
import { useCartStore } from "@/Stores/CartStore";
import { useShopStore } from "@/Stores/ShopStore";
import { computed, onMounted, ref, watch } from "vue";
import { Link, usePage } from "@inertiajs/vue3";
import ImgSelector from "@/Components/ImgSelector.vue";
import Icon from "@/Components/Icon.vue";
import Base from '@/Layouts/Base.vue';
import QuantitySelector from '@/Components/QuantitySelector.vue';
import LoadingImg from '@/Components/LoadingImg.vue';
import PriceTag from '@/Components/PriceTag.vue';
import PageHead from '@/Components/PageHead.vue';
import { shopIsOpen } from '@/Helpers/shopTime';
import dayjs from 'dayjs';

defineOptions({
    layout: [Base, HasCart]
});

const cart = useCartStore();
const shop = useShopStore();
const item = ref<Item | null>(null);
const isAddingToCart = ref(false);
const quantity = ref(1);
const sizeVariants = ref<ItemVariant[] | null>(null);
const colorVariants = ref<ItemVariant[] | null>(null);
const otherVariants = ref<ItemVariant[] | null>(null);
const selectedSizeIndex = ref<number | null>(null);
const selectedColorIndex = ref<number | null>(null);
const selectedOtherIndex = ref<number | null>(null);
const imageIndex = ref(0);
const selectedVariantImageIndices = ref<number[]>([]);

const images = computed(() => {
    return item.value?.images.map((image) => image.url) ?? [];
});

const activeGoals = computed(() => {
    if (item.value.goalContribution <= 0) {
        return [];
    }
    return shop.goals.filter((goal) => {
        return dayjs().isBefore(dayjs(goal.dateClose))
    });
});

const imageLabels = computed(() => {
    const labels: Record<number, string> = {};
    if (!item.value || !item.value.images) {
        return labels;
    }
    for (const i in item.value.images) {
        const image = item.value.images[i];
        if (image.variantId) {
            labels[i] = item.value.variants.find((variant) => variant.id === image.variantId)?.variantName ?? '';
        }
    }
    return labels;
});

const inStock = computed(() => {
    if (item.value.stockLimit) {
        return (item.value.stockLimit - item.value.metadata.qtySold) > 0
    }
    return true;
})

const canAddToCart = computed(() => {
    if (!inStock.value) {
        return { status: false, requiredMessage: '' };
    }
    let status = true;
    const required = [];
    if (sizeVariants.value && sizeVariants.value.length > 0 && selectedSizeIndex.value === null) {
        required.push('Size');
        status = false;
    }
    if (colorVariants.value && colorVariants.value.length > 0 && selectedColorIndex.value === null) {
        required.push('Color');
        status = false;
    }
    if (otherVariants.value && otherVariants.value.length > 0 && selectedOtherIndex.value === null) {
        required.push('Other');
        status = false;
    }
    if (quantity.value < 1 || isNaN(quantity.value)) {
        required.push('Quantity');
        status = false;
    }
    if (item.value?.stockLimit && item.value.metadata.qtySold >= item.value.stockLimit) {
        status = false;
    }

    return { status: status, requiredMessage: `Required selections: ${required.join(', ')}.` };
});

const page = usePage();

const props = defineProps<{
    item: Item;
}>();

onMounted(async () => {
    await shop.initialize(page.props.shopId);

    item.value = props.item;

    sizeVariants.value = item.value?.variants.filter((variant) => variant.type === "size") ?? [];
    colorVariants.value = item.value?.variants.filter((variant) => variant.type === "color") ?? [];
    otherVariants.value = item.value?.variants.filter((variant) => variant.type === "other") ?? [];

    if (sizeVariants.value.length === 1) {
        selectedSizeIndex.value = 0;
    }
    if (colorVariants.value.length === 1) {
        selectedColorIndex.value = 0;
    }
    if (otherVariants.value.length === 1) {
        selectedOtherIndex.value = 0;
    }
});

watch(selectedSizeIndex, (value) => {
    if (value !== null) {
        updateImageIndex(sizeVariants.value![value].id);
        updateRelevantImages();
    }
});

watch(selectedColorIndex, (value) => {
    if (value !== null) {
        updateImageIndex(colorVariants.value![value].id);
        updateRelevantImages();
    }
});

watch(selectedOtherIndex, (value) => {
    if (value !== null) {
        updateImageIndex(otherVariants.value![value].id);
        updateRelevantImages();
    }
});

async function addToCart() {
    if (!item.value) {
        return;
    }
    isAddingToCart.value = true;

    const cartItem: Item = JSON.parse(JSON.stringify(item.value));
    const variants: ItemVariant[] = [];
    if (selectedSizeIndex.value !== null) {
        variants.push(sizeVariants.value![selectedSizeIndex.value]);
    }
    if (selectedColorIndex.value !== null) {
        variants.push(colorVariants.value![selectedColorIndex.value]);
    }
    if (selectedOtherIndex.value !== null) {
        variants.push(otherVariants.value![selectedOtherIndex.value]);
    }

    await cart.addItem(cartItem, variants, quantity.value);
    isAddingToCart.value = false;
}

function updateImageIndex(variantId: number) {
    const images = item.value.images.filter((image) => image.variantId === variantId);
    if (images.length > 0) {
        imageIndex.value = item.value.images.indexOf(images[0]);
    }
}

function updateRelevantImages() {
    const relevantIndices: number[] = [];
    if (selectedSizeIndex.value !== null) {
        relevantIndices.push(...item.value.images.filter((image) => image.variantId === sizeVariants.value![selectedSizeIndex.value].id).map((image) => item.value.images.indexOf(image)));
    }
    if (selectedColorIndex.value !== null) {
        relevantIndices.push(...item.value.images.filter((image) => image.variantId === colorVariants.value![selectedColorIndex.value].id).map((image) => item.value.images.indexOf(image)));
    }
    if (selectedOtherIndex.value !== null) {
        relevantIndices.push(...item.value.images.filter((image) => image.variantId === otherVariants.value![selectedOtherIndex.value].id).map((image) => item.value.images.indexOf(image)));
    }
    selectedVariantImageIndices.value = relevantIndices;
}

</script>

<style lang="scss" scoped>
@use "bulma/sass/utilities/mixins";

.img-selector {
    height: fit-content;
    min-height: 30rem;
    width: 100%;
    position: relative;
}

.item-options {
    width: 100%;
    height: fit-content;
}

.shop-item-wrapper {
    gap: 1.5rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.color-preview {
    width: 1rem;
    height: 1rem;
    border-radius: 50%;
    outline: white solid .1rem;
}

@include mixins.widescreen {
    .shop-item-wrapper {
        width: 100%;
        flex-direction: row;
    }
}

@include mixins.until-widescreen {
    .img-selector {
        max-width: 60vh;
        min-width: 40rem;
        margin: auto;
    }
}

@include mixins.touch {
    .img-selector {
        max-height: 100%;
    }
}

@include mixins.mobile {
    .img-selector {
        max-width: 35rem;
        min-width: 0;
    }
}
</style>
