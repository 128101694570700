<template>
    <div class="columns is-multiline">
        <div class="column is-12">
            <Field v-model="form.name" label="Name" :error="form.errors.name" />
        </div>

        <div class="column is-12">
            <Field is="textarea" v-model="form.description" label="Description" :error="form.errors.description" />
        </div>

        <div class="column is-12">
            <Field v-model="form.slug" :error="form.errors.slug" label="Slug" />
        </div>

        <div class="column is-12">
            <label class="label">Open Time (in current timezone)</label>
            <button @click="usingOpenTime = !usingOpenTime" class="button is-outlined" :class="{
                'is-success': usingOpenTime,
                'is-danger': !usingOpenTime
            }">Use Open Time: {{ usingOpenTime ? "Yes" : "No" }}</button>
            <input v-if="usingOpenTime" v-model="dateOpen" type="datetime-local" class="is-primary input is-width-100 has-text-white mt-2" />
        </div>

        <div class="column is-12">
            <label class="label">Close Time (in current timezone)</label>
            <button @click="usingCloseTime = !usingCloseTime" class="button is-outlined" :class="{
                'is-success': usingCloseTime,
                'is-danger': !usingCloseTime
            }">Use Open Time: {{ usingCloseTime ? "Yes" : "No" }}</button>
            <input v-if="usingCloseTime" v-model="dateClose" type="datetime-local" class="is-primary input is-width-100 has-text-white mt-2" />
        </div>

        <div class="column is-12">
            <Field
                is="select"
                v-model="form.visibility"
                :error="form.errors.visibility"
                label="Visibility"
            >
                <option value="private">Private (Admins only)</option>
                <option value="unlisted">Unlisted (Hidden but anyone with link can access)</option>
                <option value="public">Public</option>
            </Field>
        </div>

        <div class="column is-12">
            <ButtonGroup
                v-model="form.goalType"
                :error="form.errors.goalType"
                label="Goal Type"
            >
                <GroupedButton
                    this-value="dollar"
                    class="is-warning"
                >
                    Dollar Amount
                </GroupedButton>
                <GroupedButton
                    this-value="vote"
                    class="is-warning"
                >
                    Vote Count
                </GroupedButton>
            </ButtonGroup>
        </div>

        <div class="column is-12">
            <FileInput
                label="Shop Icon"
                accept="image/*"
                :error="form.errors.shopIcon"
                helper-text="Recommended size: 500x500 or 1:1 aspect ratio"
                @change="form.shopIcon = ($event.target as any).files[0]"
            />
        </div>

        <div class="column is-12">
            <FileInput
                label="Banner Image"
                accept="image/*"
                :error="form.errors.bannerImage"
                helper-text="Recommended size: 1500x375 or 4:1 aspect ratio"
                @change="form.bannerImage = ($event.target as any).files[0]"
            />
        </div>

        <div class="column is-12">
            <label for="color" class="label has-text-weight-semibold">Shop Color</label>
            <button @click="usingColor = !usingColor" class="button is-outlined" :class="{
                'is-success': usingColor,
                'is-danger': !usingColor
            }">Use Shop Color: {{ usingColor ? "Yes" : "No" }}</button>
            <div v-if="usingColor" class="is-flex is-align-items-center has-gap-medium mt-3 has-width-100">
                <div>Select Color</div>
                <input v-model="form.color" type="color" id="color" class="is-flex-grow-1" style="border: none; height: 2.5rem;">
            </div>
        </div>

        <div class="column is-12">
            <FileInput
                label="Background Image (Optional)"
                accept="image/*"
                :error="form.errors.backgroundImage"
                helper-text="Recommended size: 1920x1080 or 16:9 aspect ratio. If no background is uploaded,
                the shop color will be used for the background."
                @change="form.backgroundImage = ($event.target as any).files[0]"
            />
        </div>
    </div>

    <div class="column is-12 is-flex is-justify-content-flex-end is-align-items-center">

        <div v-if="error !== ''" class="message is-danger is-flex-grow-1 mb-0 mr-5">
            <div class="message-body">{{ error }}</div>
        </div>

        <button v-if="!isSaving" class="button is-primary is-width-fit" @click="saveShop">
            <span class="icon is-small">
                <Icon :icon="mdiFloppy" />
            </span>
            <span>
                Save
            </span>
        </button>

        <button v-else class="button is-primary is-loading is-width-fit">
            <span class="icon is-small">
                <Icon :icon="mdiFloppy" />
            </span>
            <span>
                Save
            </span>
        </button>
    </div>
</template>

<script setup lang="ts">
import Field from '../common/Field.vue';
import type { Shop } from '@/Types/Shop';
import { mdiFloppy } from '@mdi/js';
import { onMounted, PropType, ref, watch } from 'vue';
import Icon from '../Icon.vue';
import { useForm } from '@inertiajs/vue3';
import { useShopStore } from '@/Stores/ShopStore';
import FileInput from "@/Components/common/FileInput.vue";
import dayjs from 'dayjs';
import {ButtonGroup, GroupedButton} from "@/Components/common/ButtonGroup";

const props = defineProps({
    shop: {
        type: Object as PropType<Shop>,
        required: false,
        default: {
            id: 0,
            name: '',
            slug: '',
            description: ''
        }
    }
});

const form = useForm({
    id: props.shop.id,
    visibility: props.shop.visibility,
    goalType: props.shop.goalType,
    name: props.shop.name,
    slug: props.shop.slug,
    description: props.shop.description,
    shopIcon: null,
    bannerImage: null,
    color: null,
    backgroundImage: null,
    dateOpen: null,
    dateClose: null,
});

const emit = defineEmits<{
    (e: "close"): void;
}>();

const error = ref<string>('');
const isSaving = ref(false);
const usingOpenTime = ref(false);
const usingCloseTime = ref(false);
const usingColor = ref(false);
const shop = useShopStore();
const dateOpen = ref();
const dateClose = ref();

watch(dateOpen, (value) => {
    if (!value) {
        form.dateOpen = null;
    } else {
        form.dateOpen = dayjs(value).toISOString();
    }
});

watch(dateClose, (value) => {
    if (!value) {
        form.dateClose = null;
    } else {
        form.dateClose = dayjs(value).toISOString();
    }
});

onMounted(() => {
    shop.initialize(props.shop.id);

    if (props.shop.dateOpen) {
        dateOpen.value = dayjs(props.shop.dateOpen).format('YYYY-MM-DDTHH:mm');
        usingOpenTime.value = true;
    }

    if (props.shop.dateClose) {
        dateClose.value = dayjs(props.shop.dateClose).format('YYYY-MM-DDTHH:mm');
        usingCloseTime.value = true;
    }

    if (props.shop.theme?.color) {
        form.color = props.shop.theme.color;
        usingColor.value = true;
    }
})

async function saveShop() {
    if (!usingOpenTime.value) {
        form.dateOpen = null;
    }

    if (!usingCloseTime.value) {
        form.dateClose = null;
    }

    if (!usingColor.value) {
        form.color = '';
    }

    if (props.shop.slug.length > 0) {
        const thisRoute = route('admin.shops.update', { shop: props.shop.slug });

        form.post(thisRoute, {
            onStart: () => {
                isSaving.value = true;
            },
            onFinish: () => {
                isSaving.value = false;
            },
            onSuccess: async () => {
                await shop.refresh();
                emit('close');
            },
            onError: (errors) => {
                error.value = errors.message;
            }
        });
    } else {
        const thisRoute = route('admin.shops.create');
        form.post(thisRoute, {
            onStart: () => {
                isSaving.value = true;
            },
            onFinish: () => {
                isSaving.value = false;
            },
            onSuccess: () => {
                emit('close');
            },
            onError: (errors) => {
                error.value = errors.message;
            }
        });
    }

    isSaving.value = false;
}

</script>

<style lang="scss" scoped>
//css is a fantastic and perfect solution to all web styling needs
::-webkit-calendar-picker-indicator {
    filter: invert(1);
}
</style>
