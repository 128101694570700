<template>
    <div class="is-flex is-flex-direction-column is-justify-content-center has-text-white"
        :style="compact ? '' : 'gap: .75rem;'">
        <div class="is-size-3 is-size-4-touch goal-title block">
            <LoadingImg :src="goal.bannerUrl" style="aspect-ratio: 4 / 1;" />
            <div class="name">{{ goal.name }}</div>
        </div>

        <div v-if="goal.type === 'target'" class="block is-flex is-flex-direction-column has-gap-medium">
            <div id="goal-progress">
                <div class="progress-bar" ref="progressBar"></div>
                <div class="contribute-bar" ref="contributeBar"></div>
            </div>
            <div class="is-size-5 is-size-4-desktop has-text-weight-semibold is-flex has-gap-medium is-width-100">

                <div v-if="type === 'dollar'" class="is-flex-grow-1">
                    <span>${{ animatedProgress.toFixed(2) }} / ${{ goal.target.toFixed(2) }}</span>
                    <span v-if="addedContributions > 0" class="has-text-warning"> (+${{ addedContributions.toFixed(2)
                        }})</span>
                </div>
                <div v-else-if="type === 'vote'" class="is-flex-grow-1">
                    <span>{{ Math.round(animatedProgress) }} / {{ goal.target }} votes</span>
                    <span v-if="addedContributions > 0" class="has-text-warning"> (+{{ addedContributions }})</span>
                </div>
            </div>
        </div>
        <div v-else class="block is-size-3 is-size-2-desktop has-text-weight-semibold" style="line-height: 1em;">
            <div v-if="type === 'dollar'">
                <span>${{ animatedProgress.toFixed(2) }}</span>
                <span v-if="addedContributions > 0" class="has-text-warning is-size-4"> (+${{
                    addedContributions.toFixed(2) }})</span>
            </div>
            <div v-else-if="type === 'vote'">
                <span>{{ Math.round(animatedProgress) }} vote{{ Math.round(animatedProgress) !== 1 ? 's' : '' }}</span>
                <span v-if="addedContributions > 0" class="has-text-warning is-size-4"> (+{{ addedContributions
                    }})</span>
            </div>
        </div>

        <div v-if="!compact" class="block is-size-5 is-flex is-align-items-center has-gap-small is-flex-wrap-wrap">
            <div v-if="goalOpen && !isReached" class="tag is-warning is-size-6">Active</div>
            <div v-else-if="!goalOpen && !isReached" class="has-text-white is-size-6">Ended</div>
            <div v-else-if="isReached && goal.type === 'target'" class="has-text-success is-size-6 mr-2">Goal reached!
            </div>

            <div v-if="goalOpen">
                Ends {{ dayjs(props.goal.dateClose).fromNow() }} ({{ dayjs(props.goal.dateClose)
                .format('MMM D, YYYY, h:mm a') }})
            </div>
        </div>

        <p class="block">{{ goal.description }}</p>
    </div>
</template>

<script setup lang="ts">
import { Goal } from '@/Types/Goal';
import dayjs from 'dayjs';
import gsap from 'gsap';
import { computed, onMounted, PropType, ref, watch } from 'vue';
import LoadingImg from './LoadingImg.vue';

const animatedProgress = ref(0);
const isReached = ref(false);
const progressBar = ref<HTMLElement | null>(null);
const contributeBar = ref<HTMLElement | null>(null);

const goalOpen = computed(() => {
    return dayjs().isBefore(props.goal.dateClose);
});

const props = defineProps({
    goal: {
        type: Object as PropType<Goal>,
        required: true
    },
    type: {
        type: String as PropType<"dollar" | "vote">,
    },
    compact: {
        type: Boolean,
        default: false
    },
    addedContributions: {
        type: Number,
        default: 0
    }
})

onMounted(() => {
    update();
});

watch(() => props.goal, () => {
    update();
});

watch(() => props.addedContributions, (newVal) => {
    if (props.goal.type === 'indeterminate') {
        return;
    }

    const val = Math.min(props.addedContributions + props.goal.current, props.goal.target) - props.goal.current;

    gsap.to(contributeBar.value, {
        width: `${(val / props.goal.target) * 100}%`,
        duration: .5,
        ease: "power2.out",
        onStart: () => {
            if (newVal > 0) {
                gsap.set(progressBar.value, {
                    borderRadius: 0
                })
            }
        },
        onComplete: () => {
            if (newVal <= 0) {
                gsap.set(progressBar.value, {
                    borderRadius: 'var(--bulma-radius)'
                })
            }
        }
    });
});

function update() {
    const animationParams = {
        duration: .75,
        delay: .2,
        ease: props.goal.current >= props.goal.target ? "power1.out" : "power3.out"
    }

    if (props.goal.type === 'indeterminate') {
        gsap.fromTo(animatedProgress, {
            value: 0
        }, {
            value: props.goal.current,
            ...animationParams
        });
    } else {
        gsap.fromTo(animatedProgress, {
            value: 0
        }, {
            value: props.goal.current,
            ...animationParams,
            onComplete: function () {
                isReached.value = animatedProgress.value >= props.goal.target;
            }
        });

        gsap.fromTo(progressBar.value, {
            width: '0%'
        }, {
            width: `${(Math.min(props.goal.current, props.goal.target) / props.goal.target) * 100}%`,
            ...animationParams,
            onComplete: () => {
                if (props.goal.current >= props.goal.target) {

                    gsap.fromTo(progressBar.value, {
                        background: "#d6e8e7"
                    }, {
                        background: "#48c78e",
                        duration: .5,
                        ease: "power2.out"
                    });

                } else {

                    if (!goalOpen.value) {
                        return;
                    }


                    gsap.fromTo(progressBar.value, {
                        '--bg-offset': 'calc(0% - 40rem)',
                    }, {
                        '--bg-offset': 'calc(100% - 0rem)',
                        repeat: -1,
                        duration: 1.5,
                        ease: "power1.in"
                    });

                }
            }
        });
    }
}
</script>

<style lang="scss" scoped>
@use 'sass:color';
@use 'bulma/sass/utilities/mixins';

.goal-title {
    display: flex;
    flex-direction: column;
    border-radius: .5rem;
    overflow: hidden;

    .name {
        font-weight: bold;
        padding: .75rem 1rem;
        background-color: var(--bulma-primary-10);
    }
}

#goal-progress {
    width: 100%;
    height: 3rem;
    border-radius: var(--bulma-radius);
    background-color: var(--bulma-primary-10);
    overflow: hidden;
    box-sizing: border-box;
    border: 1px solid;
    border-color: var(--bulma-primary-35);
    display: flex;

    .progress-bar {
        width: 0;
        height: 100%;
        border-radius: var(--bulma-radius);
        --bg-offset: calc(0% - 40rem);
        background: linear-gradient(to right,
                var(--bulma-link-text) var(--bg-offset),
                var(--bulma-primary-80) calc(var(--bg-offset) + 38rem),
                var(--bulma-link-text) calc(var(--bg-offset) + 40rem));
    }

    .contribute-bar {
        width: 0;
        height: 100%;
        border-radius: 0 var(--bulma-radius) var(--bulma-radius) 0;
        background-color: var(--bulma-warning-60);
    }
}
</style>