import { defineStore } from 'pinia';
import type { CartItem } from '@/Types/CartItem';
import type { Item } from '@/Types/Item';
import {usePage} from "@inertiajs/vue3";
import {ItemVariant} from "@/Types/ItemVariant";

const page = usePage();
export const useCartStore = defineStore('cart', {
    state: () => ({
        cartItems: undefined as CartItem[] | undefined,
        loading: true,
        isInitialized: false,
    }),
    getters: {
        getNumItems: (state) => state.cartItems?.length ?? 0,
        getTotal: (state) => {
            let total = 0;
            state.cartItems.forEach((cartItem) => {
                total += cartItem.item.price * (cartItem.quantity ?? 1);
            });
            return total;
        },
        hasGoalItems: (state) => {
            if (!state.cartItems) return false;
            return state.cartItems.some(cartItem => cartItem.item.goalContribution > 0);
        }
    },
    actions: {
        async initialize() {
            if (this.isInitialized) return;
            this.loading = true;
            await this.refresh();
            this.loading = false;
            this.isInitialized = true;
        },

        async refresh() {
            this.loading = true;

            this.cartItems = await refreshCart();

            this.loading = false;
        },

        async addItem(item: Item, variants: ItemVariant[], quantity: number) {
            this.loading = true;

            const itemInCart = this.getItemIndexInCart(item, variants);

            if (itemInCart !== undefined) {
                const newQuantity = this.cartItems[itemInCart].quantity + quantity;
                await this.updateQuantity(itemInCart, newQuantity);
                return;
            }
            this.cartItems = await addToCart(item, variants, quantity);

            this.loading = false;
        },

        async deleteByIndex(index: number) {
            this.loading = true;

            const req = await updateQuantity(index, 0);
            this.cartItems = req;

            this.loading = false;
        },

        async updateQuantity(index: number, quantity: number) {
            this.loading = true;

            const req = await updateQuantity(index, quantity);
            this.cartItems = req;

            this.loading = false;

        },

        getItemIndexInCart(item: Item, variants: ItemVariant[]): number | undefined {
            for (let i = 0; i < this.getNumItems; i++) {
                const cartItem = this.cartItems[i] as CartItem;

                if (cartItem.item.id === item.id) {

                    if (cartItem.variants.length !== variants.length) {
                        continue;
                    }

                    let found = true;
                    for (let j = 0; j < variants.length; j++) {
                        if (!cartItem.variants.find(v => v.id === variants[j].id)) {
                            found = false;
                            break;
                        }
                    }

                    if (found) {
                        return i;
                    }
                }
            }

            return undefined;
        }
    }
});

async function refreshCart(): Promise<CartItem[]> {
    const res = await fetch(route('cart.fetch'));
    const {status, payload} = await res.json();
    if (status !== 'OK') {
        throw new Error(payload);
    }
    return payload;
}

async function addToCart(item: Item, variants: ItemVariant[], quantity: number) {
    const page = usePage();

    const thisRoute = route('shop.items.add_to_cart', {
        shop: page.props.shopSlug,
        item: item.slug
    });
    const variantIds: number[] = variants.map(v => v.id);
    let res = await fetch(thisRoute, {
        method: 'POST',
        headers: {
            'X-CSRF-TOKEN': page.props.csrf,
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            variantIds,
            quantity
        }),
    });

    let {status, payload} = await res.json();
    if (status !== 'OK') {
        throw new Error(payload);
    }

    return payload;
}

async function updateQuantity(itemIndex: number, quantity: number) {
    const thisRoute = route('cart.update_quantity');
    let res = await fetch(thisRoute, {
        method: 'PATCH',
        headers: {
            'X-CSRF-TOKEN': page.props.csrf,
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            itemIndex,
            quantity
        }),
    });

    let {status, payload} = await res.json();
    if (status !== 'OK') {
        throw new Error(payload);
    }

    return payload;
}
