<template>
    <ItemVariantDialog :open="addVariantModalIsOpen" @close="addVariantModalIsOpen = false" @save="addVariant"
        :model-value="dialogModel" />

    <div class="columns is-multiline">
        <div class="column is-12">
            <Field v-model="itemForm.title" label="Title" :error="itemForm.errors.title" />
        </div>
        <div class="column is-12">
            <Field is="textarea" rows="5" v-model="itemForm.description" label="Description"
                :error="itemForm.errors.description" />
        </div>
        <div class="column is-12">
            <Field :model-value="itemForm.price.toString()"
                @update:model-value="itemForm.price = parseFloat($event ?? '0')" type="number" label="Price"
                :error="itemForm.errors.price" />
        </div>
        <div class="column is-12">
            <Field :model-value="itemForm.goalContribution.toString()"
                @update:model-value="itemForm.goalContribution = parseFloat($event ?? '0')" type="number" label="Goal Contribution"
                :error="itemForm.errors.goalContribution" />
        </div>
        <div class="column is-12">
            <Field is="select" v-model="itemForm.category" :error="itemForm.errors.category" label="Category"
                helperText="Create categories in the Category Config menu.">
                <option :value='null'>No Category</option>
                <option v-for="category in shop.categories" :value="category">
                    {{ category }}
                </option>
            </Field>
        </div>
        <div class="column is-12">
            <Field v-model="itemForm.artistName" label="Artist Name" :error="itemForm.errors.artistName" />
        </div>
        <div class="column is-12">
            <Field v-model="itemForm.artistUrl" label="Artist URL" :error="itemForm.errors.artistUrl" />
        </div>
        <div class="column is-12">
            <Field
                type="number"
                v-model="itemForm.stockLimit"
                label="Stock Limit"
                :error="itemForm.errors.stockLimit"
            />
        </div>
        <div class="column is-12">
            <ButtonGroup
                v-model="itemForm.itemType"
                label="Item Type"
            >
                <GroupedButton
                    class="is-warning"
                    this-value="physical"
                >
                    Physical
                </GroupedButton>
                <GroupedButton
                    class="is-warning"
                    this-value="digital"
                >
                    Digital
                </GroupedButton>
            </ButtonGroup>
        </div>
    </div>

    <hr>

    <template v-if="page.props.auth.can.managePrintfulContent && itemForm.itemType === 'physical'">
        <div class="column is-12 p-0">
            <Autocomplete v-model="printfulItem" :options="autocompleteOptions"
                :error="(itemForm.errors as any).printfulSyncProductId" label="Printful Items" @query="submitNewQuery"
                :is-loading="isSearching" :disabled="props.item.id > 0"
            >
                <template #option="{ option }">
                    <div class="is-flex is-align-items-center is-gap-2">
                        <figure class="image is-48x48 is-overflow-hidden" style="border-radius: 0.3rem;">
                            <img :src="option.value.thumbnail_url" alt="thumbnail" />
                        </figure>
                        <div>
                            <div>{{ option.value.name }}</div>
                            <div class="is-size-7 has-text-grey">{{ option.value.id }}</div>
                        </div>
                    </div>
                </template>
            </Autocomplete>
        </div>
    </template>

    <template v-if="itemForm.itemType === 'digital'">
        <div class="column is-12">
            <Field
                is="textarea"
                v-model="itemForm.redemptionInstructions"
                label="Redemption Instructions"
                :error="itemForm.errors.redemptionInstructions"
            />
        </div>
    </template>

    <hr v-if="itemForm.id > 0">

    <div class="column is-12" v-if="itemForm.id > 0">

<!--        <div class="block is-flex is-justify-content-flex-start">-->
<!--            <div class="is-size-4 mr-auto has-text-white">Variants</div>-->
<!--            <button class="button is-primary" @click.prevent="() => addVariantModalIsOpen = true">-->
<!--                <span class="icon is-small">-->
<!--                    <Icon :icon="mdiPlus" />-->
<!--                </span>-->
<!--                <span>-->
<!--                    Add Variant-->
<!--                </span>-->
<!--            </button>-->
<!--        </div>-->


        <div class="block is-flex is-align-items-center has-gap-medium has-text-white has-background-primary-15 p-3"
            v-for="(variant, variantIndex) in itemForm.variants" style="border-radius: .5rem;">
            <!-- <button class="button is-ghost is-small has-text-danger px-0" @click="removeVariant(variantIndex)">
                <Icon :icon="mdiClose" />
            </button> -->
            <div class="tag is-primary is-medium" style="text-transform: capitalize;">{{ variant.type }}</div>
            <div>{{ variant.variantName }}</div>
            <div v-if="variant.type === 'color' && (variant.color?.length ?? 0) > 0" style="
                    width: 1.2em; height: 1.2em; border-radius: 50%;
                " :style="{
                    backgroundColor: variant.color
                }"></div>
        </div>
    </div>

    <hr>
    <div class="is-flex is-justify-content-flex-end is-align-items-center">
        <button v-if="!isSaving" class="button is-primary is-width-fit" @click="saveItem">
            <span class="icon is-small">
                <Icon :icon="mdiFloppy" />
            </span>
            <span>
                Save
            </span>
        </button>

        <button v-else class="button is-primary is-loading is-width-fit">
            <span class="icon is-small">
                <Icon :icon="mdiFloppy" />
            </span>
            <span>
                Save
            </span>
        </button>
    </div>
</template>

<script setup lang="ts">
import type { Item } from '@/Types/Item';
import Field from '../common/Field.vue';
import { mdiFloppy, mdiPlus } from '@mdi/js';
import type { ItemVariant } from '@/Types/ItemVariant';
import ItemVariantDialog from "@/Components/Item/ItemVariantDialog.vue";
import Icon from '@/Components/Icon.vue';
import {ref, onMounted, PropType, computed } from 'vue';
import { useShopStore } from "@/Stores/ShopStore";
import { useForm, usePage } from '@inertiajs/vue3';
import { debounce } from "lodash";
import { Autocomplete, AutocompleteOption } from "@/Components/common/Autocomplete";
import {ButtonGroup, GroupedButton} from "@/Components/common/ButtonGroup";

const page = usePage();

const defaultVariant: ItemVariant = {
    id: 0,
    type: "other",
    variantName: '',
    images: [],
};

const props = defineProps({
    shopId: {
        type: Number,
        required: true
    },
    item: {
        type: Object as PropType<Item>,
        required: false,
        default: {
            id: 0,
            slug: '',
            title: '',
            itemType: 'physical',
            description: '',
            price: 0,
            category: null,
            variants: [],
        }
    },
    edit: {
        type: Boolean,
        required: false,
        default: false
    }
});

const printfulItem = ref<AutocompleteOption | null>(null);

const itemForm = useForm({
    id: props.item.id,
    slug: props.item.slug,
    title: props.item.title,
    description: props.item.description,
    price: props.item.price,
    category: props.item.category,
    stockLimit: props.item.stockLimit ?? null,
    redemptionInstructions: props.item.redemptionInstructions ?? '',
    variants: props.item.variants,
    itemType: props.item.itemType,
    printfulSyncProductId: props.item.printfulSyncProductId,
    goalContribution: props.item.goalContribution ?? 0,
    artistName: props.item.artistName ?? '',
    artistUrl: props.item.artistUrl ?? '',
});

const dialogModel = ref<ItemVariant>({ ...defaultVariant });
const isSaving = ref(false);
const addVariantModalIsOpen = ref(false);
const shop = useShopStore();

onMounted(async () => {
    await shop.initialize(props.shopId);
    if (itemForm.printfulSyncProductId) {
        await fetchPrintfulItem(itemForm.printfulSyncProductId);
    }
})

const emit = defineEmits<{
    (e: 'close'): void;
    (e: 'saved'): void;
}>();

function addVariant(newVal: ItemVariant): void {
    newVal.id = 0;
    itemForm.variants.push(JSON.parse(JSON.stringify(newVal)));
    addVariantModalIsOpen.value = false;
    return;
}

function removeVariant(idx: number): void {
    itemForm.variants.splice(idx, 1);
}

async function saveItem(): Promise<void> {
    isSaving.value = true;

    for (let i = 0; i < itemForm.variants.length; i++) {
        if (itemForm.variants[i].id === 0) {
            itemForm.variants[i].id = null;
        }
    }

    if (itemForm.id > 0 && itemForm.slug.length > 0) {
        //item exists
        const thisRoute = route('admin.shops.items.update', { shop: shop.slug, item: itemForm.slug });
        itemForm.transform((data) => ({
            ...data,
            printfulSyncProductId: printfulItem?.value?.id

        })).post(thisRoute, {
            onSuccess: () => {
                emit('saved');
                emit('close');
            }
        });
    } else {
        //new item
        const thisRoute = route('admin.shops.items.create', { shop: shop.slug });
        itemForm.transform((data) => ({
            ...data,
            printfulSyncProductId: printfulItem?.value?.id
        })).post(thisRoute, {
            onSuccess: () => {
                emit('saved');
                emit('close');
            }
        });
    }

    isSaving.value = false;
}

const isSearching = ref(false);
const bounced = debounce(queryPrintfulItems, 500, { trailing: true });
const results = ref([]);
const autocompleteOptions = computed(() => {
    return results.value.map((item: any) => {
        return {
            id: item.id,
            name: item.name,
            value: item,
        } as AutocompleteOption;
    });
});

function submitNewQuery(query: string) {
    bounced.cancel();
    isSearching.value = true;
    bounced(query);
}

async function fetchPrintfulItem(itemId: string) {
    try {
        const response = await fetch(route('admin.printful.product', { id: itemId }));

        let { status, payload } = await response.json();

        if (status !== 'OK') {
            throw new Error(payload);
        }

        printfulItem.value = {
            id: payload.id,
            name: payload.name,
            value: payload,
        };

        results.value.unshift(payload);

    } catch (error) {
        console.error(error);
    }
    finally {
        isSearching.value = false;
    }
}

async function queryPrintfulItems(query: string) {
    try {
        if (query.length < 3) {
            results.value = [];
            return;
        }

        let response = await fetch(route('admin.printful.products', { query }));

        let { status, payload } = await response.json();

        if (status !== 'OK') {
            throw new Error(payload);
        }

        results.value = payload;

    } catch (error) {
        console.error(error);
    }
    finally {
        isSearching.value = false;
    }
}
</script>
