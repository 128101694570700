<template>
    <div class="wrapper" v-if="status !== 'cancelled'">

        <div class="status" :class="{
            'has-background-warning has-text-dark active': status === 'cart',
            'has-background-success has-text-dark': checkoutLevel > 1
        }">
            <Icon :icon="mdiCart"></Icon>
            <div class="label left">Cart</div>
        </div>

        <template v-if="cart.hasGoalItems">
            <div class="spacer" :class="{
                'completed': checkoutLevel > 1,
                'in-progress': status === 'goals'
            }"></div>

            <div class="status" :class="{
                'has-background-warning has-text-dark active': status === 'goals',
                'has-background-success has-text-dark': checkoutLevel > 2
            }">
                <Icon :icon="mdiVoteOutline"></Icon>
                <div class="label center">Goals</div>
            </div>
        </template>

        <div class="spacer" :class="{
            'completed': checkoutLevel > 3,
            'in-progress': status === 'checkout'
        }"></div>

        <div class="status" :class="{
            'has-background-warning has-text-dark active': status === 'checkout',
            'has-background-success has-text-dark': checkoutLevel > 3
        }">
            <Icon :icon="mdiCreditCardOutline"></Icon>
            <div class="label right">Payment & Shipping</div>
        </div>
    </div>
</template>

<script setup lang="ts">
import { mdiCart, mdiCheck, mdiCreditCardOutline, mdiTruckDelivery, mdiVoteOutline } from '@mdi/js';
import { onMounted, ref } from 'vue';
import Icon from './Icon.vue';
import { useCartStore } from '@/Stores/CartStore';

const checkoutLevel = ref(0);
const cart = useCartStore();

const props = defineProps({
    status: {
        type: String,
        required: true
    }
});

onMounted(async () => {
    await cart.initialize();

    switch (props.status) {
        case 'cart':
            checkoutLevel.value = 1;
            break;
        case 'goals':
            checkoutLevel.value = 2;
            break;
        case 'checkout':
            checkoutLevel.value = 3;
            break;
        case 'confirmation':
            checkoutLevel.value = 4;
            break;
    }
});
</script>

<style lang="scss" scoped>
@use 'bulma/sass/utilities/mixins';

.wrapper {
    display: flex;
    align-items: center;
    gap: 1rem;
    margin-bottom: 4rem;
}

.status {
    width: 4rem;
    height: 4rem;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

    svg {
        transform: scale(1.5);
    }

    .label {
        position: absolute;
        width: fit-content;
        white-space: nowrap;
        font-size: 1.2rem;
    }
}

.status.active {

    .label {
        padding: .05rem .5rem;
        background: rgba($color: white, $alpha: .1);
        border-radius: .2rem;
    }
}

.spacer {
    height: .2rem;
    flex-grow: 1;
    background: var(--bulma-link);
    border-radius: .2rem;
}

.spacer.completed {
    background: var(--bulma-success);
}

.spacer.in-progress {
    --direction: right;
    background: linear-gradient(to var(--direction), var(--bulma-success), var(--bulma-warning));
}

@include mixins.tablet-only {
    .wrapper {
        margin-bottom: 3rem;
    }

    .status {
        width: 3rem;
        height: 3rem;

        svg {
            transform: scale(1.25);
        }

        .label {
            font-size: 1rem;
        }
    }
}

@include mixins.tablet {
    .status {
        .label {
            bottom: -2.25em;
        }

        .label.left {
            left: 0;
        }

        .label.right {
            right: 0;
            text-align: right;
        }

        .label.center {
            left: 50%;
            transform: translateX(-50%);
            text-align: center;
        }
    }
}

@include mixins.mobile {
    .wrapper {
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        gap: .75rem;
        margin-bottom: 1rem;
    }

    .status {
        width: 2.5rem;
        height: 2.5rem;

        svg {
            transform: scale(1);
        }

        .label {
            left: 3.25rem;
        }
    }

    .spacer {
        height: 1.5rem;
        width: .2rem;
        margin-left: calc(2.5rem / 2 - .1rem);
    }

    .spacer.in-progress {
        --direction: bottom;
    }
}
</style>